import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { publicRequest } from "../requestMethod"; // Ensure you have this request method set up
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";
import styled from "styled-components";
import Sponsors from "../components/sponsors/Sponsors";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 0rem;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

const Container = styled.div`
  width: 80%;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1020px) {
    width: 100%;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column;
    width: 100%;
    margin: 0 20px;
  }
`;

const Page = () => {
  const { slug } = useParams(); // Get the page slug from the URL
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const res = await publicRequest.get(`/pages/slug/${slug}`);
        setPage(res.data);
      } catch (error) {
        console.error("Error fetching page:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPage();
  }, [slug]);

  if (loading) return <p>Loading...</p>;
  if (!page) return <p>Page not found</p>;

  return (
    <>
      <InfoDiv>
        <Container>
          {/* <h1>{page.title}</h1> */}
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </Container>
      </InfoDiv>
      <Sponsors />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default Page;
