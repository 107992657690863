import React from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import gov from "../../images/Rt. Hon. Ahmadu Fintiri.jpg";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 0rem;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

const Container = styled.div`
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  line-height: 2rem;

  img {
    float: left;
    padding: 1rem;
    width: 40%;
  }

  h1 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 1rem;
    line-height: 3rem;
    color: #000;
  }

  h2 {
    color: var(--dark-bg);
  }

  ol {
    list-style-type: decimal;
    font-weight: 500;
    margin-bottom: 3rem;
    display: inline-block;
  }

  ul {
    list-style-type: square;
    font-weight: 300;
    color: var(--fifth-color);
  }

  p {
    margin-bottom: 1.5rem;
  }

  p.note {
    color: var(--first-color);
    clear: both;
  }

  @media screen and (max-width: 480px) {
    width: 90%;

    img {
      margin: 2px;
      display: inline-block;
      width: 100%;
    }

    h1 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
`;

const TopDiv = styled.div`
  width: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;

  p {
    color: #900;
    text-align: center;
    margin-bottom: 0.2rem;
  }

  p.titl {
    text-align: center;
    color: #009;
  }
`;

const ExecutiveGovernor = () => {
  return (
    <InfoDiv>
      <Container>
        <TopDiv>
          <h1>The Executive Governor of Adamawa State</h1>
          <p>RT. Hon. Ahmadu Umaru Fintiri</p>
          {/* <p className="titl">The Executive Governor Of Adamawa State</p> */}
        </TopDiv>
        <img src={gov} alt="" />

        <p>
          Rt. Hon. Ahmadu Umaru Fintiri (AUF) was born on October 27th, 1967 in
          Gulak, Madagali Local Government area of Adamawa State. He grew up in
          a world that had become so conscious of the primacy of Western
          Education and Religious knowledge, both of which he struggled to
          acquire at an early age.
        </p>
        <p>
          He had his early primary School in Central Primary School Gulak from
          1975-1981, Government Day Secondary School Gulak and Government
          Secondary School Michika from 1981-1986.
        </p>
        <p>
          AUF holds a post graduate Diploma in Policy and Strategic studies,
          from the University of Maiduguri 2004 after obtaining his first degree
          in History from the same University in 1992.
        </p>
        <p>
          Most of his teachers, mentors and contemporaries know AUF as an
          outstanding Student and an athlete throughout his boyhood and
          adolescent years. After successfully running his private business
          enterprise, he was elected to represent Madagali Constituency in 2007
          and re-elected in 2011.
        </p>
        <p>
          He held several leadership positions in the House of Assembly before
          being elected as the Speaker, a position he held until 2015. AUF was
          also, twice, The Governor of Adamawa State in Acting capacity. Even
          though brief, his tenures as Acting Governor were quite memorable
          because of the giant strides achieved.
        </p>
        <p>
          Ahmadu Umaru Fintiri is the current Governor of State and was Hon.
          Member of the Adamawa State House of Assembly. He was later appointed
          the Speaker of the House. He eventually became the acting Governor
          following the impeachment of admiral Murtala Hammmanyero Nyako in July
          2014, handed later to Bala James Ngilari after serving for three
          months.
        </p>
        <p>
          Governor Ahmadu Umaru Fintiri has every reason to celebrate his
          fifty-three anniversaries as the Governor of Adamawa State. From every
          political calculation and calibration, the unequivocal conclusion is
          that he is a man of the people who emblematizes the divine injunction
          that “power comes from God alone.” Throwback to 2019: Governor Ahmadu
          Fintiri, then an ordinary citizen without federal might, without
          security, found himself in the eye of a raging political storm,
          contrived and fermented by treacherous forces that betrayed a
          political vision and threatened to completely overturn and dismantle a
          well-nurtured political agenda to achieve their own selfish power
          grabbing ambitions. Pitted against the backdrop of a manipulated and
          deviously translated zonal power-sharing arrangement and confronted by
          the real threat of political annihilation in the face of a viscous
          opposition that will stop at nothing to secure victory, Governor
          Finitri found himself literally and practically isolated and
          surrounded as the last man standing and the only hope for the
          redemption and protection of the political soul of Adamawa State.
        </p>
        <p>
          However, he was greatly loved, admired, and adored by the people of
          Adamawa State as their champion. Ahmadu Fintiri took on the
          opposition, both within and externally, battled them to a standstill,
          defeated them on all fronts, and won the election convincingly.
          Governor Ahmadu Fintiri ascended the throne, carrying the hopes and
          aspirations of the Adamawa people on his broad shoulders. Fast forward
          to 2023: The vicious, acrimonious opposition, emboldened with federal
          might and empowered with the added authority of directing their second
          term campaign, deployed the full weight of both mercenary and
          compromised might to the political battlefield. Traditional war songs
          reigned and echoed ominously at the venues of every campaign stomp,
          and the poisoned manifesto rhetoric reeked of bitterness, inciting
          anger, unbridled hatred, and images of political purgatory worse than
          Dante’s Inferno because of the event of political defeat in 2023. But
          Governor Fintiri was fully prepared and ready for them, and believing
          in his superior strategic plans and greatly buoyed by the resilient
          will of the people, the tide once again turned to victory in the quite
          remarkable life of this political colossus. The last straw was in the
          Supreme Court, Abuja, where they had all hoped that the court would
          give judgment in their favour, in a suit seeking to nullify the free
          mandate given to Governor Fintiri in 2019.
        </p>
        <p>
          In 2023, destiny would have it, the divine hand of fate, once again
          played another master stroke and the monumental miscalculation and
          failure of the opposition. Governor Fintiri has a plethora of reasons
          to celebrate his fifty-three anniversary, but in typical charismatic,
          all action fashion, the Adamawa governor, who was nicknamed “Mr.
          Projects” by none other than the former Vice President of the country,
          Alhaji Atiku Abubakar, simply rolled up his sleeves and knuckled down
          to the business of delivering the dividends of democracy to the good
          people of Adamawa State. At the end of our first four years in office,
          the gains of the first term will be solidly consolidated. But
          addressing Adamawa people in a town hall meeting to mark the first
          year of his first term in office, the governor honestly confessed that
          normally, the state should be celebrating this important milestone
          when all the projects he flagged off will be ready for commissioning
          and correlated social activities, but these are abnormal times and
          moments like this call for subdued feelings and thankful prayers to
          God for His abundant grace and mercy upon the state and on the lives
          of Adamawa people. The scorecard of the last one and a half years has,
          however, been replete with outstanding achievements, ground-breaking
          events, and the initiation of legacy projects that have not only
          transformed the landscape of the state impressively but also ensured
          the comprehensive sustenance of the well-being of the people in line
          with the development blueprint that will be released for the second
          term in office. A summary reveals that virtually every area and sector
          has been positively impacted in the last year. The urban renewal
          programme in the capital city has progressed at an amazing pace with
          the restoration works, including underground drains, sidewalks, green
          areas, and street lights on most of the roads. The construction of the
          two multi-billion-naira flyover projects at Total Filling Station
          Junction and the Police Roundabout, all progressing simultaneously, is
          an architectural wonder to behold and is expected to be delivered
          within schedule by Traicta Nigeria Limited. This is even as work on
          the construction of major roads within the city and the building of
          several cottage hospitals is also underway. The construction of
          several critical roads across the state, including Doctors’ Quarters –
          1.6km), Mbamba Street – 1 km, Mafia Road with fall out drain gets 1.2
          km. Others are Zumo Street and links – 0.7 km) and Nybango to Yolde
          Pate Road with two span bridges (3.18 3.18 km.
        </p>
        <p>
          In Numan Local Government, Fintiri flagged off the construction of
          3.5km on the Izala-Gweda Malam-Rafa-Wayam township road. This project,
          among others, will help to mitigate flooding, something residents have
          had to deal with for years. Jada Local Government gets 3.8 km of
          township road and the rehabilitation of 0.4 washout section along
          Jada-Mbulo road. In Hong Local Government, the governor also flagged
          off the construction of a 3.2km township road linking European
          Quarters, not forgetting the flagging-off of the construction of 6.6
          km township roads in Gulak, Madagali, and 5.1 km in Michika.
          Straddling most of the local government areas in Adamawa north,
          central, and south senatorial districts are progressing
          satisfactorily, with some having reached 92 percent completion, all to
          be delivered on schedule. This is in addition to the construction of
          the Scope newspaper road linking Government House. In the last year,
          the administration has accelerated work on the long-abandoned roads to
          the hinter communities of Jada and Ganye in Jada and Ganye Local
          Government Areas of the State, with appreciable work already done on
          the Ganye main township road The commitment to deliver on the promise
          to complete these landmark projects and actualize the age-long
          yearnings of the indigenes and residents of these local government
          areas to connect and access the rest of the state by tarred road, has
          been the prime motivating factor driving the zeal and energy to
          complete these projects and bring joy to the people.
        </p>
        <p>
          In fact, the people of Madagali and Michika are witnessing projects
          for the first time since the advent of insurgency in the three local
          governments of Michika, Madagali, and Mubi. Apart from roads,
          significant progress was also made in the delivery of health care and
          education infrastructure and services. The mother-and-child hospital
          is virtually complete, fully furnished, equipped, and only undergoing
          necessary testing and preparations for commissioning. Also completed
          is the road network within ADSU, Mubi community. In the area of
          education, the strategic intervention of the administration in the
          restoration, upgrade, and expansion of secondary schools across the
          state, which commenced in the first phase, continues to deliver
          remarkable results and improvements in the quality of the public
          education system, as funds have already been released for the
          comprehensive restoration and upgrade of scores of dilapidated
          secondary schools across the state, including renowned schools like
          Madagali Central Primary School and Farabaraune Primary School in
          Demsa Local Government Area. Renovation and upgrading of GJSS Belel
          Girls, Labondo Primary School, the famous Gwaida Mallam Primary
          School, Numan in Numan Local Government Area, Tambu Primary School,
          Kugbo in Shelleng Local Government Area, Bachure Primary School,
          Bachure in Yola north Local Government Area, and GDJSS Shagari, in
          Yola south Local Government Area, have all been more than 85 per cent
          completed On the international front, the plan for the construction of
          model schools across the state is in top gear. In the area of
          agriculture and in line with the administration’s promise to refocus
          on agricultural development, the abandonment of fertilizer processing
          plants with guaranteed off-taker opportunities for over 10,000
          farmers, and inspiring capacity to stimulate the fertilizer revolution
          in the state and related industries and create thousands of direct and
          indirect jobs for our people is coming on stream.
        </p>
      </Container>
    </InfoDiv>
  );
};

export default ExecutiveGovernor;
