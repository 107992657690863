import "./App.css";
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import SideBar from "./components/SideBar";
import Home from "./pages/Home";
// import Menu from "./components/menu/Menu";
import Login from "./pages/Login";
import Register from "./pages/Register";
import News from "./pages/News";
import ReadNews from "./pages/ReadNews";
import ScrollToTop from "./ScrollToTop";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import SingleProject from "./pages/SingleProject";
import Budget from "./pages/Budget";
import Dashboard from "./pages/Dashboard";
import RequireAuth from "./pages/ProtectedRoutes";
import Modal from "./components/header/Modal";
import Suggestion from "./pages/Suggestion";
import Maps from "./pages/Maps";
import PartnerProjects from "./pages/PartnerProjects";
import Monitor from "./pages/Monitoring";
import CitizenFramework from "./pages/CitizenFramework";
import CitizenBudget from "./pages/CitizenBudget";
// import Navbarmenu from "./components/navbar/Navbar";
import AboutPlanning from "./pages/AboutPlanning";
import StateBudget from "./components/budget/StateBudget";
import InternationalPartners from "./pages/Partners";
import NationalPartners from "./pages/NationalPartners";
import AboutGovernor from "./pages/AboutGovernor";
import Grants from "./pages/Grants";
import Credits from "./pages/Credits";
import Loan from "./pages/Loan";
import BudgetPerformance from "./pages/BudgetPerformance";
import PartnerDashboards from "./components/dashboard/PartnerDashboard";
import Navigation from "./components/Navigation/Nav";
import LgaDashboard from "./components/dashboard/LgaDashboard";
import PartnersMatrix from "./components/dashboard/PartnersMatrix";
import DurableSolution from "./pages/DurableSolution";
import NgCares from "./pages/Ng-cares";
import Page from "./pages/Pages";

function App() {
  // const [isOpen, setIsOpen] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [isOpen1, setIsOpen1] = useState(false);

  const user =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.name
      : false;

  // const toggle = () => {
  //   setIsOpen(!isOpen);
  // };
  const toggler = () => {
    setIsOpen1(!isOpen1);
  };
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        {/* {showNav && <Navbarmenu />} */}
        {showNav && <Navigation />}

        {/* {showNav && <SideBar isOpen={isOpen} toggle={toggle} />} */}
        <Modal isOpen={isOpen1} toggler={toggler} />
        <Routes>
          <Route path="/">
            <Route index element={<Home funcNav={setShowNav} />} />
            <Route
              path="login"
              element={
                user ? (
                  <Home funcNav={setShowNav} />
                ) : (
                  <Login funcNav={setShowNav} />
                )
              }
            />
            <Route
              path="register"
              element={
                user ? (
                  <Home funcNav={setShowNav} />
                ) : (
                  <Register funcNav={setShowNav} />
                )
              }
            />
            <Route path="news" element={<News funcNav={setShowNav} />} />
            <Route path="page/:slug" element={<Page funcNav={setShowNav} />} />
            <Route path="maps" element={<Maps funcNav={setShowNav} />} />
            <Route
              path="suggestion"
              element={
                <RequireAuth redirectTo="/">
                  <Suggestion funcNav={setShowNav} />
                </RequireAuth>
              }
            />
            <Route
              path="dashboard"
              element={<Dashboard funcNav={setShowNav} />}
            />
            <Route
              path="partners_dashboard"
              element={<PartnerDashboards funcNav={setShowNav} />}
            />
            <Route
              path="lga_dashboard"
              element={<LgaDashboard funcNav={setShowNav} />}
            />
            <Route
              path="partners_matrix"
              element={<PartnersMatrix funcNav={setShowNav} />}
            />
            <Route
              path="citizens_budget"
              element={<Budget funcNav={setShowNav} />}
            />
            <Route
              path="budget_performance"
              element={<BudgetPerformance funcNav={setShowNav} />}
            />
            <Route
              path="state_budget"
              element={<StateBudget funcNav={setShowNav} />}
            />
            <Route
              path="m&e_framework"
              element={<Monitor funcNav={setShowNav} />}
            />
            <Route
              path="citizen_engagement_framework"
              element={<CitizenFramework funcNav={setShowNav} />}
            />
            <Route path="grants" element={<Grants funcNav={setShowNav} />} />
            <Route path="credits" element={<Credits funcNav={setShowNav} />} />
            <Route path="loan" element={<Loan funcNav={setShowNav} />} />
            <Route path="ng-cares" element={<NgCares funcNav={setShowNav} />} />
            <Route
              path="citizen_budget"
              element={<CitizenBudget funcNav={setShowNav} />}
            />
            <Route
              path="budget/:budgetId"
              element={<SingleProject funcNav={setShowNav} />}
            />
            <Route path="about" element={<About funcNav={setShowNav} />} />
            <Route
              path="about_the_governor"
              element={<AboutGovernor funcNav={setShowNav} />}
            />
            <Route
              path="about_commission"
              element={<AboutPlanning funcNav={setShowNav} />}
            />
            <Route
              path="projects"
              element={<Projects funcNav={setShowNav} />}
            />
            <Route
              path="projects/:projectId"
              element={<SingleProject funcNav={setShowNav} />}
            />
            <Route
              path="international_partners"
              element={<InternationalPartners funcNav={setShowNav} />}
            />
            <Route
              path="national_partners"
              element={<NationalPartners funcNav={setShowNav} />}
            />
            <Route
              path="local_partners"
              element={<PartnerProjects funcNav={setShowNav} />}
            />
            <Route
              path="partners_projects/:projectId"
              element={<SingleProject funcNav={setShowNav} />}
            />
            <Route path="contact" element={<Contact funcNav={setShowNav} />} />
            <Route
              path="durable_solutions"
              element={<DurableSolution funcNav={setShowNav} />}
            />
            <Route
              path="news/:newsId"
              element={<ReadNews funcNav={setShowNav} />}
            />
            <Route path="logout">
              <Route index element={<Home funcNav={setShowNav} />} />
            </Route>
          </Route>
          {/* <Route path="unauthenticated" element={<Unauthorized />} />
          <Route path="*" element={<Error />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
