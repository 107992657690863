import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  FiAlignRight,
  FiXCircle,
  FiChevronDown,
  FiChevronRight,
} from "react-icons/fi";
import axios from "axios";
import "./navigation.css";
import Barner from "../header/Barner";
import logo from "../../images/new_logo.png";

const Navigation = () => {
  const user =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.id
      : false;

  const [menuItems, setMenuItems] = useState([]);
  const [isMenu, setIsMenu] = useState(false);
  const [isResponsiveClose, setIsResponsiveClose] = useState(false);

  useEffect(() => {
    axios
      .get("https://api.admethub.ng/api/v1/menus") // Update with your API URL
      .then((response) => setMenuItems(response.data))
      .catch((error) => console.error("Error fetching menu:", error));
  }, []);

  const toggleClass = () => {
    setIsMenu(!isMenu);
    setIsResponsiveClose(!isResponsiveClose);
  };

  const parentMenus = menuItems.filter((item) => !item.parent_id);
  const getChildMenus = (parentId) =>
    menuItems.filter((item) => item.parent_id === parentId);

  return (
    <>
      <Barner />
      <nav>
        <div className="navbar">
          {isResponsiveClose ? (
            <FiXCircle onClick={toggleClass} className="bx bx-menu" />
          ) : (
            <FiAlignRight onClick={toggleClass} className="bx bx-menu" />
          )}
          <div className={`nav-links ${isMenu ? "nav-links-show" : ""}`}>
            <ul className="links">
              {/* {parentMenus.map((parent) => (
                <li key={parent._id}>
                  <NavLink
                    className="NavLink"
                    onClick={toggleClass}
                    to={parent.url}
                  >
                    {parent.title}
                  </NavLink>
                  {getChildMenus(parent._id).length > 0 && (
                    <>
                      <FiChevronDown className="bx bxs-chevron-down about-arrow arrow" />
                      <ul className="about-sub-menu sub-menu">
                        {getChildMenus(parent._id).map((child) => (
                          <li key={child._id}>
                            <NavLink
                              className="NavLink"
                              onClick={toggleClass}
                              to={child.url}
                            >
                              {child.title}
                            </NavLink>
                            {getChildMenus(child._id).length > 0 && (
                              <li className="more">
                                <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                                <ul className="more-sub-menu sub-menu">
                                  {getChildMenus(child._id).map((subChild) => (
                                    <li key={subChild._id}>
                                      <NavLink
                                        className="NavLink"
                                        onClick={toggleClass}
                                        to={subChild.url}
                                      >
                                        {subChild.title}
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
              ))} */}
              {parentMenus.map((parent) => (
                <li key={parent._id}>
                  {parent.url.startsWith("http") ? (
                    <a
                      onClick={toggleClass}
                      href={parent.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="NavLink"
                    >
                      {parent.title}
                    </a>
                  ) : (
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={parent.url}
                    >
                      {parent.title}
                    </NavLink>
                  )}

                  {getChildMenus(parent._id).length > 0 && (
                    <>
                      <FiChevronDown className="bx bxs-chevron-down about-arrow arrow" />
                      <ul className="about-sub-menu sub-menu">
                        {getChildMenus(parent._id).map((child) => {
                          const grandChildren = getChildMenus(child._id); // Call once

                          return (
                            <li key={child._id}>
                              {child.url.startsWith("http") ? (
                                <a
                                  onClick={toggleClass}
                                  href={child.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="NavLink"
                                >
                                  {child.title}
                                </a>
                              ) : (
                                <NavLink
                                  className="NavLink"
                                  onClick={toggleClass}
                                  to={child.url}
                                >
                                  {child.title}
                                </NavLink>
                              )}

                              {grandChildren.length > 0 && (
                                <li className="more">
                                  <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                                  <ul className="more-sub-menu sub-menu">
                                    {grandChildren.map((subChild) => (
                                      <li key={subChild._id}>
                                        {subChild.url.startsWith("http") ? (
                                          <a
                                            onClick={toggleClass}
                                            href={subChild.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="NavLink"
                                          >
                                            {subChild.title}
                                          </a>
                                        ) : (
                                          <NavLink
                                            className="NavLink"
                                            onClick={toggleClass}
                                            to={subChild.url}
                                          >
                                            {subChild.title}
                                          </NavLink>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </li>
              ))}
              {user ? (
                <li>
                  <NavLink
                    className="NavLink"
                    onClick={toggleClass}
                    to={`/suggestion`}
                  >
                    Suggest a project
                  </NavLink>
                </li>
              ) : (
                <li>
                  <NavLink
                    className="NavLink"
                    onClick={toggleClass}
                    to={`/login`}
                  >
                    Sign in to participate
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
          <div className="logo">
            <img src={logo} alt="logo" />
            <p className="comp_name">
              Adamawa State <br />
              Technological Monitoring Hub <br />
              (CITIZENS' EYES AND EARS)
            </p>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
